import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { displayErrorMessage } from "@agricircle/shared/redux";
import { useAsyncApi, deserializeJsonapiEntities } from "@agricircle/shared/hooks";

export const useFieldApi = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// List crops
    const listCrops = async (callback) => {
        const result = await sendRequest("GET", "crops?sort=id");
        if (result.status < 300) {
            if (callback) callback(deserializeJsonapiEntities(result.data));
        } else {
            dispatch(displayErrorMessage(t("error-list-crops"), result.data));
        }
        return result;
    };

    return { listCrops };
};
