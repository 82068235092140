import { combineReducers } from "redux";
import { messageReducer } from "@agricircle/shared/redux";
import {farmsReducer } from "@agricircle/shared/farms/redux";
import farmiq from "./farmiq";

export const reducer = combineReducers({
    messages: messageReducer,
    farms: farmsReducer,
    farmiq
});

export const RootState = reducer;
