import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { MapContainer, LayersControl, GeoJSON, ImageOverlay, FeatureGroup, Popup, useMap } from "react-leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Autocomplete, Fab, TextField, Tooltip } from "@mui/material";
import { Search } from "@mui/icons-material";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { GOOGLE_API_KEY } from "../../../../utils/googleMaps";
import { selectMapPosition, selectMapLayer } from "../../../redux/selectors";
import { setMapPosition, setMapLayer } from "../../../redux/reducer";
import { CustomMapControls, MapButton } from "./tools";

const MapRecenterTool = ({ onClick, title }) => (<Tooltip placement="right" title={title}>
    <MapButton data-cy="btn-map-recenter" onClick={onClick}>
        <CenterFocusWeakIcon />
    </MapButton>
</Tooltip>);

const updateBounds = (field, currentBounds) => {
    const bounds = currentBounds || [];
    field.boundaries.coordinates[0].forEach(c => {
        if (!bounds.length)
            bounds.push([c[1], c[0]], [c[1], c[0]]);
        else {
            if (bounds[0][0] > c[1])
                bounds[0][0] = c[1];
            if (bounds[0][1] > c[0])
                bounds[0][1] = c[0];
            if (bounds[1][0] < c[1])
                bounds[1][0] = c[1];
            if (bounds[1][1] < c[0])
                bounds[1][1] = c[0];
        }
    });
    return bounds;
};

export const FarmMap = forwardRef(({ farm, farmTs, fields, fieldOptions, selectedFieldsRef, fieldOverlaysRef, controls, onFieldPopup, onFieldClick, children }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const layer = useSelector(selectMapLayer);
    const mapPosition = useSelector(selectMapPosition);
    const [bounds, setBounds] = useState(null);
    const [searchInProgress, setSearchInProgress] = useState(null);
    const clickRef = useRef(null);
    const mapRef = useRef(null);
    const previousFarm = useRef({ farmId: undefined, timeout: null });
    const [_, setDelayedRedraw] = useState(0);  // eslint-disable-line
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        let resizeTimer;
        const handleResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                // Trigger the callback when the resize has "ended"
                setHeight(window.innerHeight);
            }, 500); // Adjust the debounce time as needed (in milliseconds)
        };

        // Attach the handleResize function to the window resize event
        window.addEventListener('resize', handleResize);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        mapRef.current && mapRef.current.closePopup();
        if (fields !== null) computeBounds();
        return () => {
            if (previousFarm.current.timeout) {
                previousFarm.current.timeout = null;
                clearTimeout(previousFarm.current.timeout);
            }
        };
    }, [farm?.id, farmTs, fields?.length, selectedFieldsRef?.current]);

    useEffect(() => {
        if (bounds?.length) {
            handleRecenter();
        }
    }, [bounds?.[0]?.[0], bounds?.[0]?.[1], bounds?.[1]?.[0], bounds?.[1]?.[1]]);

    useImperativeHandle(ref, () => ({
        computeBounds
    }));

    function computeBounds(customBounds) {
        if (customBounds) {
            setBounds(customBounds);
            return customBounds;
        }
        const farmBounds = [];
        if (fields?.length) {
            fields.forEach(field => {
                if (!selectedFieldsRef?.current?.length || selectedFieldsRef.current.includes(field.id))
                    updateBounds(field, farmBounds);
            });
        }
        setBounds(farmBounds);
        return farmBounds;
    }

    function handleFieldClick(event, fieldId) {
        clickRef.current = fieldId;
        if (onFieldClick && onFieldClick(fieldId, event?.originalEvent?.shiftKey)) {
            computeBounds();
        }
    }

    if (previousFarm.current.farmId !== farm?.id) {
        if (!previousFarm.current.timeout) {
            previousFarm.current.timeout = setTimeout(() => {
                previousFarm.current.farmId = farm.id;
                previousFarm.current.timeout = null;
                setDelayedRedraw((value) => value + 1);
            }, 200);
        }
    }

    if (!bounds) return null;

    const mapProps = mapPosition || (bounds?.length
        ? { bounds }
        : {
            center: farm.location?.coordinates
                ? { lat: farm.location.coordinates[1], lng: farm.location.coordinates[0] }
                : { lat: 47.192294, lng: 8.755809 },
            zoom: 13
        }
    );

    function handleSearchChange(event, field, reason) {
        if (reason === "blur") {
            const fieldBounds = [];
            updateBounds(field, fieldBounds);
            handleRecenter(null, fieldBounds);
            onFieldClick && onFieldClick(field.id);
        }
        setSearchInProgress(null);
    }

    function handleFeature(feature, layer) {
        if (feature?.properties?.id) {
            const handlers = {
                click: (e) => handleFieldClick(e, feature.properties.id)
            };
            layer.on(handlers);
        }
    };

    function handleRecenter(event, customBounds) {
        const map = mapRef.current;
        map && map.closePopup();
        if (map && (bounds?.length || customBounds?.length)) {
            map.fitBounds(customBounds || bounds, { padding: [5, 5] });
        }
        dispatch(setMapPosition(null));
    }

    const MapEventHandlers = () => {
        const map = useMap();

        useEffect(() => {
            function handleClick() {
                if (clickRef.current) {
                    clickRef.current = null;
                    return;
                }
                handleFieldClick(null, null);
            }
            function handleMove() {
                const position = { center: map.getCenter(), zoom: map.getZoom() };
                dispatch(setMapPosition(position));
            }
            function handleLayer(e) {
                const layer = e.layer.options.type;
                dispatch(setMapLayer(layer));
            }
            map.on("click", handleClick);
            map.on("moveend", handleMove);
            map.on("baselayerchange", handleLayer);
            return () => {
                map.off("click", handleClick);
                map.off("moveend", handleMove);
                map.off("baselayerchange", handleLayer);
            };
        }, []);
    };

    return ((!previousFarm.current.timeout || null) && <MapContainer
        id="map"
        animate={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        key={`map-${height}-${farmTs || 0}-${farm?.id || 0}`}
        style={{ width: "100%", height: `${height - 50}px` }}
        ref={mapRef}
        zoomSnap={0.1}
        fadeAnimation={false}
        zoomAnimation={false}
        {...mapProps}
    >
        <LayersControl position="topright">
            <LayersControl.BaseLayer name={t("farms.label-map-roadmap")} checked={!layer || layer === "roadmap"}>
                <ReactLeafletGoogleLayer apiKey={GOOGLE_API_KEY} type="roadmap" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={t("farms.label-map-satellite")} checked={layer === "satellite"}>
                <ReactLeafletGoogleLayer apiKey={GOOGLE_API_KEY} type="satellite" />
            </LayersControl.BaseLayer>
        </LayersControl>
        <FeatureGroup>
            {fields && fields.map((f, idx) => {
                const selected = selectedFieldsRef?.current && selectedFieldsRef.current.includes(f.id)
                    ? { color: "green", weight: 3, opacity: 1 }
                    : {};
                const options = fieldOptions?.[f.id]
                    ? { ...fieldOptions?.[f.id] }
                    : { color: "blue", weight: 2, opacity: 1, fillOpacity: 0 };
                return (<GeoJSON
                    key={`geo${idx}`}
                    pathOptions={{ ...options, ...selected }}
                    data={{ type: "Feature", geometry: f.boundaries, properties: { id: f.id } }}
                    onEachFeature={handleFeature}
                >
                    {(onFieldPopup || null) && <Popup>{onFieldPopup(f)}</Popup>}
                </GeoJSON>);
            })}
        </FeatureGroup>
        <FeatureGroup>
            {fieldOverlaysRef?.current && fieldOverlaysRef.current.map((o, idx) => (<ImageOverlay
                url={o.url}
                bounds={o.bounds}
                opacity="100%"
                zIndex={1200}
            />))}
        </FeatureGroup>
        <FeatureGroup>
            <CustomMapControls position="topleft">
                {controls}
                <MapRecenterTool onClick={handleRecenter} title={t("label-recenter-map")} />
            </CustomMapControls>
            <CustomMapControls position="topright">
                <Fab component="a" size="small" sx={{ textTransform: "none" }} disabled={!fields?.length}>
                    {searchInProgress
                        ? <Autocomplete
                            ref={(input) => { input && setTimeout(() => input.click(), 100); }}
                            disablePortal
                            autoSelect
                            selectOnFocus
                            blurOnSelect
                            clearOnBlur
                            clearOnEscape
                            openOnFocus
                            autoComplete
                            size="small"
                            id="field-select"
                            options={fields}
                            value={searchInProgress?.id ? searchInProgress : null}
                            getOptionLabel={f => f.name || ""}
                            renderInput={(params) => <TextField {...params} autoFocus label="Filter" />}
                            sx={{ width: 300, position: "absolute", top: 0, right: 0, backgroundColor: "white" }}
                            onClose={() => setSearchInProgress(null)}
                            onChange={handleSearchChange}
                        />
                        : <Search onClick={() => setSearchInProgress(selectedFieldsRef?.current || {})} />
                    }
                </Fab>
            </CustomMapControls>
        </FeatureGroup>
        {children}
        <MapEventHandlers />
    </MapContainer>);
});
