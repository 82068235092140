import React from "react";
import { useTranslation } from "react-i18next";
import { Stack, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as GeometryUtil from "leaflet-geometryutil";
import { EditControl } from "react-leaflet-draw";
import Control from "react-leaflet-custom-control";
import { NOT_EMPTY_VALIDATOR } from "../../../../hooks/validation";
import { usePrompt } from "../../../../hooks/modal";

export const MapDrawFieldTool = ({ onCreate }) => {
    const { t } = useTranslation();
    const prompt = usePrompt();

    function handleCreated(e) {
        const coordinates = e.layer.getLatLngs()[0];
        const area = GeometryUtil.readableArea(GeometryUtil.geodesicArea(coordinates), true);
        prompt(
            t("farms.text-create-field", { area }),
            (name) => {
                const field = {
                    name,
                    boundaries: { type: "Polygon", coordinates: [coordinates.map(c => [c.lng, c.lat])] }
                };
                onCreate(field);
            },
            {
                label: t("farms.label-field-name"),
                validator: NOT_EMPTY_VALIDATOR,
                closeModal: () => e.target.removeLayer(e.layer)
            }
        );
    }

    return <EditControl
        position="topleft"
        onCreated={handleCreated}
        draw={{
            rectangle: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: { showArea: true, allowIntersection: false }
        }}
        edit={{ remove: false, edit: false }}
    />;
};

export const CustomMapControls = ({ children, ...props }) => {
    return (<Control {...props}>
        <Stack spacing={1}>
            {children}
        </Stack>
    </Control>
    );
};


export const MapButton = styled(ButtonBase)(() => ({
    minWidth: "35px",
    minHeight: "35px",
    border: "2px solid lightgrey",
    borderRadius: "4px",
    padding: 0,
    backgroundColor: "white",
    color: "inherit",
    "&:hover": { backgroundColor: "#f4f4f4" }
}));

export const handleFieldPopup = (field, action, mapRef) => {
    if (action === "marker") {
        // mapRef is an idx
        switch (field.status) {
            case undefined:
                return blueIcon(mapRef);
            case "OK": return greenIcon(mapRef);
            default:
                return redIcon(mapRef);
        }
    }
    return <FieldPopup field={field} />;
}

const FieldPopup = ({ field }) => {
    const { t } = useTranslation();

    //popup content
    return (<Stack>
        <Typography
            variant="subtitle1"
            style={{
                width: "99%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }}
        >
            {field.name}
        </Typography>
        <Stack direction="row" alignItems="center">
            <Typography variant="body" style={{ marginRight: "auto" }}>
                {field.status ? t(field.status === "OK" ? "farms.import.title-field-import" : "farms.import.title-field-import-error", { area: field.area }) : t("farms.title-field-area", { area: field.area })}
            </Typography>
            {(!field.unprocessable_error || null) && <IconButton
                data-cy="btn-map-field-delete"
                onClick={() => handleDeleteField(field, mapRef)}
                edge="end"
            >
                <DeleteOutlineIcon />
            </IconButton>}
            {(!field.unprocessable_error || null) && <IconButton
                data-cy="btn-map-field-edit"
                onClick={() => handleEditField(field, mapRef)}
                edge="end"
            >
                <EditIcon />
            </IconButton>}
            <IconButton
                data-cy="btn-map-field-select"
                onClick={() => handleZoomToField(field, mapRef)}
                edge="end"
            >
                <SearchIcon />
            </IconButton>
        </Stack>
        {
            (field.unprocessable_error || null) && <Alert severity="error" sx={{ fontSize: "80%" }}>
                {field.unprocessable_error}
            </Alert>
        }
    </Stack >);
}

const blueIcon = (id) => new L.Icon({
    iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: `marker-field-${id}`
});

const greenIcon = (id) => new L.Icon({
    iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: `marker-field-${id}`
});

const redIcon = (id) => new L.Icon({
    iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: `marker-field-${id}`
});

