import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { displayErrorMessage, displayInfoMessage } from "@agricircle/shared/redux";
import { useAsyncApi } from "@agricircle/shared/hooks";

export const useCropHistoryApi = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// list crop history
    const list = async (fieldId, callback) => {
        const result = await sendRequest("GET", `fields/${fieldId}/crop_history`);
        if (result.status < 300) {
            if (callback) callback(result.data);
        } else {
            dispatch(displayErrorMessage(t("error-list-crop-history"), result.data));
        }
        return result;
    };

    ////////////////// Add crop history entry
    const add = async (fieldId, payload, callback) => {
        const result = await sendRequest("POST", `fields/${fieldId}/crop_history`, payload);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("message-crop-history-updated")));
            if (callback) callback();
        } else {
            dispatch(displayErrorMessage(t("error-update-crop-history"), result.data));
        }
        return result;
    };

    ////////////////// Patch crop history entry

    const patch = async (fieldId, startDate, payload, callback) => {
        const result = await sendRequest("PATCH", `fields/${fieldId}/crop_history/${startDate}`, payload);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("message-crop-history-updated")));
            if (callback) callback();
        } else {
            dispatch(displayErrorMessage(t("error-update-crop-history"), result.data));
        }
        return result;
    };

    ////////////////// Delete crop history entry

    const _delete = async (fieldId, startDate, callback) => {
        const result = await sendRequest("DELETE", `fields/${fieldId}/crop_history/${startDate}`);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("message-crop-history-updated")));
            if (callback) callback();
        } else {
            dispatch(displayErrorMessage(t("error-update-crop-history"), result.data));
        }
        return result;
    };

    return { list, add, patch, delete: _delete };
};
