import { useState } from "react";

const validateProperty = (options, value) => {
    if (!options) return [null, value];
    if (value === undefined && !options.validateUndef) {
        const newOptions = { ...options, invalid: null };
        return [newOptions, value];
    }

    if (options.type == "number") {
        value = Number(value);
        if (isNaN(value))
            return [options, undefined];
    } else if (options.type == "boolean")
        value = Boolean(value);
    const newOptions = { ...options };
    const validator = options.validator;
    if (!validator)
        newOptions.invalid = null;
    else {
        if (Array.isArray(validator)) {
            for (const v of validator) {
                newOptions.invalid = v(value);
                if (newOptions.invalid !== null)
                    break;
            }
        } else
            newOptions.invalid = validator(value);
    }
    return [newOptions, value];
};

export const useValidation = (options) => {
    const [validation, setValidation] = useState(options ? { ...options } : {});

    const isValid = (property) => {
        if (property) return validation[property]?.invalid == null;

        for (const [property, validationOption] of Object.entries(validation)) {
            if (property != "_modified" && validationOption.invalid != null) return false;
        }
        return true;
    };

    const error = (property) => validation[property]?.invalid || null;

    const isModified = () => Boolean(validation._modified);

    const validate = (property, value) => {
        if (!property) {
            if (!validation._modified) setValidation({ ...validation, _modified: true });
            return value;
        }
        [options, value] = validateProperty(validation[property], value);
        if (value === undefined) return undefined;
        if (options) {
            setValidation({ ...validation, [property]: options, _modified: true });
        } else {
            setValidation({ ...validation, _modified: true });
        }
        return value;
    };

    const validateMultiple = (object, properties, customValidator) => {
        const newValidation = { ...validation, _modified: true };
        if (!properties) properties = Object.keys(object);
        for (let i = 0; i < properties.length; i++) {
            const property = properties[i];
            const [options,] = validateProperty(validation[property], object[property]);
            if (options && customValidator) {
                const invalid = customValidator(property, object[property]);
                if (invalid != null)
                    options.invalid = invalid;
            }
            if (options)
                newValidation[properties[i]] = options;
        }
        setValidation(newValidation);
    };

    const init = (object) => {
        const newValidation = { ...validation };
        let invalid = false;
        for (const property of Object.keys(validation)) {
            if (property == "_modified") continue;
            const [options,] = validateProperty(validation[property], _get_property(object, property));
            if (options && options.invalid != null) {
                //newValidation._modified = true;
                invalid = true;
                newValidation[property] = options;
            }
        }
        if (invalid || newValidation._modified) {
            delete newValidation._modified;
            setValidation(newValidation);
        }
    };

    const reset = () => {
        const newValidation = {};
        for (const [key, options] of Object.entries(validation)) {
            if (key == "_modified") continue;
            const newOptions = { ...options };
            delete newOptions.invalid;
            newValidation[key] = newOptions;
        }
        setValidation(newValidation);
    };

    const modifiedProperties = (object) => {
        if (object == null || !validation._modified) return {};
        const changedValues = {};
        for (const [key, options] of Object.entries(validation)) {
            if (key == "_modified" || !("invalid" in options)) continue;
            const value = _get_property(object, key);
            if (value == undefined) continue;

            if (key.indexOf(".")) {
                const path = key.split(".");
                let o = object, changed = changedValues;
                for (let p = 0; p < path.length - 1; p++) {
                    o = o[path[p]];
                    changed[path[p]] = o;
                    changed = changed[path[p]];
                }
                changed[path[path.length - 1]] = value;
            } else
                changedValues[key] = value;
        }
        return changedValues;
    };

    return { init, isValid, error, isModified, validate, validateMultiple, modifiedProperties, reset };
};

const _get_property = (object, property) => {
    if (!property.indexOf(".")) return object[property];
    const path = property.split(".");
    let o = object;
    for (let p = 0; p < path.length; p++) {
        o = o[path[p]];
        if (o == undefined) return o;
    }
    return o;
};

export const ID_VALIDATOR = (errorMessage) => (value) => /^[a-z][a-z\d_.\-]+$/.test(value) ? null : (errorMessage || "");

export const NAME_VALIDATOR = (errorMessage) => (value) => {
    const trimmed = value?.trim();
    return value?.length == trimmed?.length && trimmed?.length >= 2 && trimmed?.length <= 32 ? null : (errorMessage || "");
};

export const NOT_EMPTY_VALIDATOR = (value) => {
    if (value == null) return "";
    switch (typeof value) {
        case "string": return value.trim().length > 0 ? null : "";
        case "object": return Array.isArray(value) ? (value.length > 0 ? null : "") : (Object.keys(value).length > 0 ? null : "");
        default: return null;
    }
};

export const NUMBER_VALIDATOR = (min, max, errorMessage) => (value) => (max ? value >= min && value <= max : value <= min) ? null : (errorMessage || "");

export const RANGE_VALIDATOR = (min, max, errorMessage) => (value) => value >= min && value <= max ? null : (errorMessage || "");

export const LENGTH_VALIDATOR = (min, max, errorMessage) => (value) => {
    const l = value?.length || 0;
    return (max ? l >= min && l <= max : l <= min) ? null : (errorMessage || `Length must be between ${min} and ${max || "-"}`);
};

const emailRegex = /^[^@]+@[^@]+?\.[^@]+$/;
export const EMAIL_VALIDATOR = (errorMessage) => (value) => (!value?.length || emailRegex.test(value)) ? null : (errorMessage || "");

const httpsUrlRegex = /^(https:\/\/)([\w-]+(\.[\w-]+)+\/?)([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
export const URL_VALIDATOR = (errorMessage) => (value) => (!value?.length || httpsUrlRegex.test(value)) ? null : (errorMessage || "");

export const setNestedProperty = (obj, path, value) => {
    const keys = path.split(".");
    const lastKey = keys.pop();

    keys.reduce((acc, key) => {
        acc[key] = acc[key] || {};
        return acc[key];
    }, obj)[lastKey] = value;
    return obj;
};
