import React, { useState, useMemo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, IconButton, TextField, Autocomplete, Stack, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "@mui/material/utils";
import { Circle } from "react-leaflet";
import { apiUrl } from "../../hooks/asyncApi";
import { useConfirm } from "../../hooks/modal";
import { findLocations } from "../../utils/googleMaps";
import { reloadFarms } from "../redux/reducer";
import { useFarmApi } from "../hooks";
import { FarmMap } from "./widgets/map";

export const InitialFarmSetup = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const farmApi = useFarmApi();
    const [googleLocation, setGoogleLocation] = useState(null);
    const [farmLocation, setFarmLocation] = useState(null);
    const farmMapRef = useRef(null);

    useEffect(() => {
        const defaultLoc = { lat: 47.192294, lng: 8.755809 };
        const bounds = [[defaultLoc.lat - 0.005, defaultLoc.lng - 0.005], [defaultLoc.lat + 0.005, defaultLoc.lng + 0.005]];
        farmMapRef.current.computeBounds(bounds);

        // for CY purposes ONLY
        const handleCyEvent = (e) => setFarmLocation(e.farmLocation);
        document.addEventListener("myCyEvent", handleCyEvent);
        // Clean up the event listener when the component unmounts
        return () => document.removeEventListener("myCyEvent", handleCyEvent);
    }, []);


    function handleLocationChange(gLoc) {
        if (!gLoc) {
            setFarmLocation(null);
            setGoogleLocation(null);
            return;
        }
        const loc = gLoc.geometry.location;
        const bounds = [[loc.lat - 0.005, loc.lng - 0.005], [loc.lat + 0.005, loc.lng + 0.005]];
        farmMapRef.current.computeBounds(bounds);
        setFarmLocation(loc);
        setGoogleLocation(gLoc);
    }

    function handleLogout() {
        window.location.href = `${apiUrl}/auth/logout?after_logout=${window.location.href}`;
    }

    function handleGetStarted() {
        confirm(
            t("farms.text-confirm"),
            () => {
                const location = { type: "Point", coordinates: [farmLocation.lng, farmLocation.lat] };
                farmApi.createFarm({ name: "My farm", location }, () => dispatch(reloadFarms()));
            }
        );
    }

    return (<div style={{ position: "relative" }} id="farm_registration">
        <FarmMap
            ref={farmMapRef}
            defaultLocation={farmLocation}
        >
            {(farmLocation || null) && <Circle center={farmLocation} radius={100} />}
        </FarmMap>
        <Stack direction="row" sx={{ position: "absolute", bottom: 0, left: 0, width: "100vw", zIndex: 10000, backgroundColor: "white" }} alignItems="center">
            <Tooltip placement="bottom" title={t("farms.text-help")}>
                <LocationOnIcon id="farm-locator-icon" />
            </Tooltip>
            <GoogleLocation value={googleLocation} onChange={handleLocationChange} />
            <IconButton data-cy="get-started" onClick={farmLocation ? handleGetStarted : handleLogout}>
                {farmLocation ? <CheckIcon color="primary" /> : <CloseIcon />}
            </IconButton>
        </Stack>
    </div>);
};


const GoogleLocation = ({ value, onChange }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);

    const fetch = useMemo(() => debounce((request, callback) => {
        findLocations(request, callback);
    }, 400), []);

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(inputValue, (loc, results) => {
            if (active) {
                let newOptions = value ? [value] : [];
                if (results?.length) newOptions = [...newOptions, ...results];
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (<Autocomplete
        id="farm-locator"
        getOptionLabel={(option) =>
            typeof option === "string" ? option : option.formatted_address
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        fullWidth
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={t("farms.text-no-locations")}
        onChange={(e, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            onChange(newValue);
        }}
        onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
        }}
        renderInput={(params) => (
            <TextField {...params} margin="dense" variant="standard" placeholder={t("farms.text-locate-farm")} />
        )}
        renderOption={(props, option, attributes) => {
            return (<li {...props} key={attributes.index}>
                <Typography variant="body2" color="text.secondary">
                    {option.formatted_address}
                </Typography>
            </li>);
        }}
        size="small"
    />);
};
