import IconButton from "@mui/material/IconButton";

export function NewActionButton({ ...props }) {
    return <IconButton
        title="New"
        data-cy="new-action-button"
        {...props}
        className="fa fa-plus"
        size="small"
        sx={{ color: "primary.main" }}
    />;
};

export function EditActionButton({ ...props }) {
    return <IconButton
        title="Edit"
        data-cy="edit-action-button"
        {...props}
        className="fa fa-pencil"
        size="small"
        sx={{ color: "goldenrod" }}
    />;
};

export function DeleteActionButton({ ...props }) {
    return <IconButton
        title="Delete"
        data-cy="delete-action-button"
        {...props}
        className="fa fa-trash-alt"
        size="small"
    />;
};