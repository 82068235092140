import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DialogContent, ButtonGroup, Button, Chip, Alert, Menu, MenuItem, Typography, Stack } from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";
import { useLoading } from "../../../loadingContext";
import { useModal } from "../../../hooks/modal";
import { displayInfoMessage } from "../../../redux/messages";
import { setFarmImport, setFarms } from "../../redux/reducer";
import { selectEditFields, selectFarm } from "../../redux/selectors";
import { useFarmApi } from "../../hooks";


const FilesInput = styled("input")(() => ({
    display: "none"
}));

export const StartImport = () => {
    const { t } = useTranslation();
    const { closeModal, onClose } = useModal();
    const dispatch = useDispatch();
    const farm = useSelector(selectFarm);
    const farmApi = useFarmApi();
    const inputRef = useRef(null);
    const [importFiles, setImportFiles] = useState([]);
    const { startLoading, doneLoading } = useLoading();

    useEffect(() => setTimeout(() => inputRef.current.click(), 100) && undefined, []);

    function handleFileChange(event) {
        const inputFiles = event.currentTarget.files;
        setImportFiles(Array.from(inputFiles));
    }

    function handleSelectImportFiles(e) {
        e.preventDefault();
        e.stopPropagation();
        inputRef.current.click();
    }

    function handleImport(e) {
        e.preventDefault();
        e.stopPropagation();
        const formData = new FormData();
        importFiles.forEach(file => {
            formData.append("files", file);
        });
        formData.append("farm_id", farm.id);
        formData.append("overlap_action", "update");
        farmApi.startImport(farm.id, formData, (result) => {
            dispatch(setFarmImport({ farm_id: farm.id }));
            if (result?.farm_id) {
                startLoading();
                setTimeout(async () => {
                    await farmApi.listImportFields((data) => {
                        if (data.error || data.errors || data.fields) {
                            dispatch(setFarmImport(data));
                            onClose(data);
                        }
                    });
                    doneLoading();
                    closeModal();
                }, 1500);
            } else
                closeModal();
        });
    }

    return (<DialogContent sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <label>
            <Button
                variant="outlined"
                size="small"
                component="span"
                onClick={handleSelectImportFiles}
            >
                {t("farms.import.btn-select-files")}
            </Button>
            {(importFiles.length || null) && <Button
                data-cy="btn-import-upload"
                variant="contained"
                size="small"
                component="span"
                onClick={handleImport}
                startIcon={<BackupIcon />}
                sx={{ marginLeft: 1 }}
            >
                {t("farms.import.btn-import")}
            </Button>}
            <FilesInput accept=".dbf,.shp,.shx,.prj,.geojson,.json,.kml" multiple type="file" ref={inputRef} onChange={handleFileChange} />
        </label>
        <div>
            {importFiles.map((file, idx) => <Chip key={`fn${idx}`} label={file.name} sx={{ marginRight: 0.5, marginBottom: 0.5 }} />)}
        </div>
    </DialogContent>);
};

export const FinalizeImport = ({ importStatus }) => {
    const { t } = useTranslation();
    const { closeModal } = useModal();
    const dispatch = useDispatch();
    const farmApi = useFarmApi();
    const fields = useSelector(selectEditFields);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const { area, fieldCount, fieldProperties } = useMemo(() => {
        if (!fields?.length) return { area: 0, fieldCount: 0, fieldProperties: null };
        // the occurrences object keeps track of the number of occurrences of non-numerical properties
        const occurrences = {};
        let area = 0;
        let fieldCount = 0;
        for (const f of fields) {
            if (f.status != "OK") continue;
            fieldCount++;
            area += f.area;
            if (f.properties)
                Object.entries(f.properties).forEach(([p, v]) => {
                    if (occurrences[p] == undefined)
                        occurrences[p] = { count: 0, values: new Set() };
                    if (!occurrences[p].values.has(v)) {
                        occurrences[p].values.add(v);
                        occurrences[p].count++;
                    }
                });
        }
        // keep all properties that appear in at least half the fields
        const minOccurrences = fieldCount / 2;
        const fieldProperties = [];
        Object.entries(occurrences).forEach(([p, occurrence]) => { if (occurrence.count >= minOccurrences) fieldProperties.push({ name: p, count: occurrence.count }); });
        fieldProperties.sort((a, b) => b.count - a.count);
        return { area, fieldCount, fieldProperties };
    }, [fields]);

    function handleActivateAllAction() {
        closeModal();
        farmApi.activateImportFields(null, () => {
            dispatch(setFarms(null));
            dispatch(setFarmIm(null));
        });
    }

    function handleClearImportAction() {
        closeModal();
        farmApi.resetImport(() => dispatch(setFarmImport(null)));
    }

    function handleMenuOpen(e) {
        if (!menuAnchorEl)
            setMenuAnchorEl(e.currentTarget);
    }

    function handleMenuClose() {
        if (menuAnchorEl)
            setMenuAnchorEl(null);
    }

    function handleBatchRename(renameToProp) {
        handleMenuClose();
        farmApi.renameImportFields(renameToProp, (data) => {
            dispatch(displayInfoMessage(t("farms.import.text-batch-rename-successful")));
            dispatch(setFarmImport(data));
            closeModal();
        });
    }

    return (<DialogContent>
        {(area === 0 || null) && <Alert severity="error" sx={{ marginBottom: 1 }}>
            <Typography>{t("farms.import.text-no-importable-fields")}</Typography>
            {importStatus?.errors?.[0]?.details || null}
            <FieldErrors fields={fields} />
        </Alert>}
        {(fieldCount || null) && <Typography variant="body1">
            {t("farms.import.text-importable-fields", { count: fieldCount })}
        </Typography>}
        <ButtonGroup sx={{ marginTop: 1 }}>
            {(Boolean(area) && fieldProperties?.length || null) && <Button
                size="small"
                variant="outlined"
                data-cy="btn-import-batch-rename"
                onClick={handleMenuOpen}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {t("farms.import.btn-batch-rename")}
            </Button>}
            <Button
                size="small"
                variant="outlined"
                data-cy="btn-import-reset"
                onClick={handleClearImportAction}
            >
                {t("farms.import.btn-clear")}
            </Button>
            {(Boolean(area) || null) && <Button
                size="small"
                variant="contained"
                data-cy="btn-import-activate-all"
                onClick={handleActivateAllAction}
            >
                {t("farms.import.btn-activate-all")}
            </Button>}
        </ButtonGroup>
        {(fieldProperties?.length || null) && <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem disabled>{t("farms.import.text-batch-rename")}</MenuItem>
            {fieldProperties.map(p => <MenuItem
                key={`prop-${p.name}`}
                data-cy={`menu-import-prop-${p.name}`}
                onClick={() => handleBatchRename(p.name)}
            >{p.name} ({p.count})
            </MenuItem>)}
        </Menu>}
    </DialogContent>);
};

const FieldErrors = ({ fields }) => {
    if (!fields?.length) return null;
    const errorFields = fields.filter(f => f.status && f.status !== "OK");
    if (!errorFields.length) return null;
    return (<Stack>
        {errorFields.map(f => <div key={`err${f.id}`}>{f.name} - {f.status}</div>)}
    </Stack>);
};
