export const GOOGLE_API_KEY = "AIzaSyBc6gKK5bf-wbQoG8VWjSyhrPCppv5T4HM";

export const findLocations = (address, callback) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_API_KEY}`;
    fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data.results.length > 0) {
                // Extract latitude and longitude from the response
                const location = data.results[0].geometry.location;
                callback(location, data.results);
            } else {
                callback();
            }
        })
        .catch(error => {
            console.error("Error:", error);
            callback();
        });
};

export const viewLatLngUrl = (location, zoomLevel) => {
    const url = `https://www.google.com/maps/@${location.lat},${location.lng},${zoomLevel || 20}z`;
    return url;
};
