import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "@agricircle/shared";
import "./i18n";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <AuthProvider>
        <Suspense fallback="...">
            <App />
        </Suspense>
    </AuthProvider>
);
