import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "@agricircle/shared";
import { displayErrorMessage } from "@agricircle/shared/redux";
import { useAsyncApi } from "@agricircle/shared/hooks";
import { selectFarms } from "@agricircle/shared/farms/redux";
import { useLoadFarms } from "@agricircle/shared/farms/hooks";
import { setCrops } from "../redux/farmiq";
import { useFieldApi } from "./fieldApi";

export const loadFarmIQ = () => {
    const dispatch = useDispatch();
    const farms = useSelector(selectFarms);
    const { profile } = useAuthContext();
    const fieldApi = useFieldApi();

    useLoadFarms(profile?.id, true);

    useEffect(() => {
        if (!profile?.roles || farms) return;
        fieldApi.listCrops((crops) => dispatch(setCrops(crops)));
    }, [Boolean(profile?.roles), Boolean(farms)]);
};


export const useFarmIQApi = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// Get assessment preview
    const previewAssessment = async (farmId, callback) => {
        const result = await sendRequest("GET", `dora/snapshots/${farmId}/preview`);
        if (result.status < 300) {
            if (callback) callback(result.data);
        } else {
            dispatch(displayErrorMessage(t("error-preview-assessment"), result.data));
        }
        return result;
    };

    ////////////////// Request assessment
    const requestAssessment = async (farmId, callback) => {
        const result = await sendRequest("POST", `dora/snapshots/${farmId}`);
        if (result.status < 300) {
            if (callback) callback(result.status == 202);
        } else {
            dispatch(displayErrorMessage(t("error-process-assessment"), result.data));
        }
        return result;
    };

    ////////////////// Get assessment
    const getAssessment = async (farmId, callback) => {
        const result = await sendRequest("GET", `dora/snapshots/${farmId}`);
        if (result.status < 300) {
            if (callback) callback(result.data);
        } else {
            dispatch(displayErrorMessage(t("error-get-assessment"), result.data));
        }
        return result;
    };

    ////////////////// Place order
    const placeOrder = async (farmId, callback) => {
        const result = await sendRequest("POST", `dora/snapshots/${farmId}/order?redirect_to=${window.location.href}`);
        if (result.status < 300) {
            if (callback) callback(result.data);
        } else {
            dispatch(displayErrorMessage(t("error-order-assessment"), result.data));
        }
        return result;
    };

    ////////////////// List orders
    const listOrders = async (callback) => {
        const result = await sendRequest("GET", "dora/snapshots/orders");
        if (result.status < 300) {
            if (callback) callback(result.data);
        } else {
            dispatch(displayErrorMessage(t("error-list-orders"), result.data));
        }
        return result;
    };

    ////////////////// Get order
    const getOrder = async (orderId, callback) => {
        const result = await sendRequest("GET", `dora/snapshots/orders/${orderId}`);
        if (result.status < 300) {
            if (callback) callback(result.data);
        } else {
            dispatch(displayErrorMessage(t("error-get-order"), result.data));
        }
        return result;
    };

    ////////////////// Support form
    const sendSupportForm = async (form, callback) => {
        const result = await sendRequest("POST", "dora/support", form);
        if (result.status < 300) {
            if (callback) callback(result.data);
        } else {
            dispatch(displayErrorMessage(t("error-request-support"), result.data));
        }
        return result;
    };

    return { previewAssessment, requestAssessment, getAssessment, placeOrder, listOrders, getOrder, sendSupportForm };
};
