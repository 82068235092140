import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [showProgress, setShowProgress] = useState(null);
    const loadingCount = useRef(0); // Set the initial value
    const timeoutRef = useRef(null);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
        };
    }, [loadingCount]);

    const startLoading = () => {
        if (loadingCount.current === 0) {
            timeoutRef.current = setTimeout(() => setShowProgress(true), 1000);
            setShowProgress(false); // active but not visible yet
        }
        loadingCount.current += 1;
    };

    const doneLoading = () => {
        if (loadingCount.current == 0) return;

        loadingCount.current -= 1;
        if (loadingCount.current === 0) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
            }
            setShowProgress(null);
        }
    };

    return (
        <LoadingContext.Provider value={{ startLoading, doneLoading }}>
            {children}
            <LoadingOverlay visible={showProgress !== null} showProgress={showProgress} />
        </LoadingContext.Provider>
    );
};

export const useLoading = () => useContext(LoadingContext);


const GlassPane = styled("div")(({ visible, showProgress }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: showProgress ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    visibility: visible ? "visible" : "hidden"
}));


export const LoadingOverlay = ({ visible, showProgress }) => {
    return (<GlassPane visible={visible} showProgress={showProgress}>
        <CircularProgress color="secondary" style={{ visibility: visible && showProgress ? "visible" : "hidden" }} />
    </GlassPane>);
};
