import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DialogContent, Typography, Alert, Button } from "@mui/material";
import { useAuthContext } from "@agricircle/shared";
import { useModal } from "@agricircle/shared/hooks";
import { monthAndDay } from "@agricircle/shared/utils";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { useFarmIQApi } from "../hooks/farmiq";
import { usePolling } from "../PollingContext";


const FarmIQPreview = ({ preview, onProcess }) => {
    const { t } = useTranslation();
    const { profile } = useAuthContext();
    const farmIQPermission = Boolean(profile?.organization?.custom_permissions?.includes("api_farmiq"));
    const doraPermission = Boolean(profile?.organization?.default_permissions?.includes("api_dora"));

    const body = <Typography variant="body">
        <p>{t("text-prepare-assessment", { numFields: preview.fields.length, area: preview.area })}</p>
    </Typography>;

    if (!farmIQPermission && !preview.free)
        return (<div>
            {body}
            <Button disabled onClick={() => onProcess(true)}>{t("btn-goto-payment")}</Button>
        </div>);

    if (preview.free == "granted")
        return (<div>
            {body}
            <div>
                <Alert severity="info">{t(farmIQPermission ? "message-200ha-plan" : "message-35ha-plan")}</Alert>
                <Button onClick={onProcess}>{t("btn-generate-assessment")}</Button>
            </div>
        </div>);

    if (preview.free == "denied")
        return (<div>
            {body}
            <div>
                <Alert severity="warning">{t("message-free-plan-denied")}</Alert>
                <Button disabled onClick={() => onProcess(true)}>{t("btn-goto-payment")}</Button>
            </div>
        </div>);

    if (farmIQPermission && doraPermission)
        return (<div>
            {body}
            <div>
                <Alert severity="warning">{t("message-billable-operation")}</Alert>
                <Button onClick={onProcess}>{t("btn-generate-assessment")}</Button>
            </div>
        </div>);

    return (<div>
        {body}
        <div>
            <Alert severity="warning">{t("message-free-plan-exceeded", { info: preview.free })}</Alert>
            {/* <Button disabled onClick={onProcess}>{t("btn-goto-payment")}</Button> TODO: revise if billing becomes an option*/}
        </div>
    </div>);
};

export default () => {
    const { closeModal } = useModal();
    const { t } = useTranslation();
    const farmIQApi = useFarmIQApi();
    const farm = useSelector(selectFarm);
    const [preview, setPreview] = useState();
    const { isProcessing, setProcessing } = usePolling();

    useEffect(() => {
        if (!farm?.fields?.length || preview || isProcessing) return;
        farmIQApi.previewAssessment(farm.id, setPreview);
    }, [Boolean(farm), Boolean(preview), isProcessing]);

    if (farm == null) return;

    function handleProcess(withPayment) {
        if (withPayment === true) {
            farmIQApi.placeOrder(farm.id, (order) => {
                closeModal();
                if (order.checkout_url)
                    window.location.href = order.checkout_url;
                else
                    setProcessing(farm.id);
            });
        } else {
            farmIQApi.requestAssessment(farm.id, (isProcessing) => {
                if (isProcessing) {
                    setProcessing(farm.id);
                    closeModal();
                } else {
                    setPreview(null);
                }
            });
        }
    }

    return (<DialogContent>
        {(isProcessing || null) && t("message-assessment-in-progress")}
        {(!isProcessing || null) && (farm.fields.length == 0
            ? <Alert severity="warning">{t("message-no-fields-to-process")}</Alert>
            : preview
                ? preview.area
                    ? <FarmIQPreview preview={preview} onProcess={handleProcess} />
                    : <Alert severity="warning">
                        {t("message-prepare-no-assessment", { date: monthAndDay(t, preview.next_update) })}
                    </Alert>
                : null
        )}
    </DialogContent>);
};
