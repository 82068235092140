import { useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FeatureGroup } from "react-leaflet";
import { Stack, IconButton, Tooltip } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { SelfEditTextField } from "../../widgets/selfEditTextField";
import { useModal, useConfirm } from "../../hooks/modal";
import { displayInfoMessage } from "../../redux/messages";
import { useFarmApi } from "../hooks";
import { addField, updateField, deleteField, setFarmImport } from "../redux/reducer";
import { selectFarm, selectEditFields, selectImportStatus, selectImportFarmId } from "../redux/selectors";
import { StartImport, FinalizeImport } from "./widgets/importFields";
import { FarmMap, MapDrawFieldTool, MapButton } from "./widgets/map";


export const IMPORT_OPTIONS = {
    none: {
        icon: <CloudUploadOutlinedIcon />,
        title: "farms.import.title-import"
    },
    in_progress: {
        icon: <CloudUploadOutlinedIcon className="ac-spin" />,
        title: "farms.import.title-in-progress"
    },
    error: {
        icon: <CloudUploadOutlinedIcon color="error" className="ac-shake" />,
        title: "farms.import.title-review"
    },
    review: {
        icon: <CloudUploadOutlinedIcon color="primary" className="ac-shake" />,
        title: "farms.import.title-review"
    },
    other: {
        icon: <CloudUploadOutlinedIcon style={{ color: "orange" }} />,
        title: "farms.import.title-other-farm"
    }
};

const MapImportFieldsTool = ({ importFarmId, importStatus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const farmApi = useFarmApi();
    const { openModal } = useModal();
    const confirm = useConfirm();
    const options = IMPORT_OPTIONS[importStatus];

    if (!options) return null;

    function handleImportFields() {
        switch (importStatus) {
            case "none":
                openModal
                    (t("farms.import.title"),
                        <StartImport />,
                        (importStatus) => {
                            openModal(
                                t("farms.import.title-review"),
                                <FinalizeImport importStatus={importStatus} />,
                                null,
                                { maxWidth: "sm", fullWidth: false }
                            );
                        },
                        { maxWidth: "xs" }
                    );
                break;
            case "error":
            case "review":
                openModal(
                    t("farms.import.title-review"),
                    <FinalizeImport importStatus={importStatus} />,
                    null,
                    { maxWidth: "sm", fullWidth: false }
                );
                break;
            case "other":
                confirm(
                    t("farms.import.text-switch-farm"),
                    () => {
                        farmApi.listFields(importFarmId, null, (fields) => dispatch(setSelectedFarm(importFarmId, fields)))
                    },
                    { confirmAction: t("farms.import.btn-switch") }
                );
                break;
            case "in_progress":
                farmApi.listImportFields((data) => {
                    if (!data.error && !data.errors && !data.fields) {
                        dispatch(displayInfoMessage(t("farms.import.text-still-in-progress"), data));
                    } else {
                        dispatch(setFarmImport(data));
                        openModal(
                            t("farms.import.title-review"),
                            <FinalizeImport importStatus={data} />,
                            null,
                            { maxWidth: "sm", fullWidth: false }
                        );
                    }
                });
                break;
            default:
                break;
        }
    }

    return (<Tooltip placement="right" title={t(options.title)}>
        <MapButton data-cy="btn-import-fields" onClick={() => !options.disabled && handleImportFields()}>
            {options.icon}
        </MapButton>
    </Tooltip>);
};

export const FarmEditor = ({ onFieldClick }) => {
    const dispatch = useDispatch();
    const farmApi = useFarmApi();
    const farm = useSelector(selectFarm);
    const editFields = useSelector(selectEditFields);
    const importFarmId = useSelector(selectImportFarmId);
    const importStatus = useSelector(selectImportStatus);
    const farmMapRef = useRef();

    const fields = useMemo(() => {
        if (!editFields?.length) return editFields;
        return editFields.filter(f => Boolean(f.boundaries));
    }, [farm?.id, farm?.timestamp, editFields?.length]);


    const farmLocation = farm.location ? { lat: farm.location.coordinates[1], lng: farm.location.coordinates[0] } : { lat: 47.470520, lng: 8.474440 };

    useEffect(() => {
        if (fields && !fields.length) {
            const bounds = [[farmLocation.lat - 0.005, farmLocation.lng - 0.005], [farmLocation.lat + 0.005, farmLocation.lng + 0.005]];
            farmMapRef.current.computeBounds(bounds);
        }
    }, [farm?.id, fields?.length]);

    const controls = useMemo(() => {
        return <MapImportFieldsTool importFarmId={importFarmId} importStatus={importStatus} />;
    }, [importFarmId, importStatus]);

    if (fields == null) return;

    const handleNewField = (attributes) => {
        //mapRef.current && mapRef.current.closePopup();
        farmApi.createField(attributes, farm.id, (field) => {
            dispatch(addField(field));
        });
    };

    return (<FarmMap
        ref={farmMapRef}
        defaultLocation={farmLocation}
        controls={controls}
        farm={farm}
        farmTs={farm.timestamp}
        fields={fields}
        onFieldClick={onFieldClick}
    >
        <FeatureGroup>
            <MapDrawFieldTool onCreate={handleNewField} />
        </FeatureGroup>
    </FarmMap>);
};

export const FieldEditBase = ({ field, onDelete }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const farmApi = useFarmApi();

    function handleDeleteField() {
        if (confirm(t("farms.message-confirm-delete-field"))) {
            onDelete && onDelete();
            farmApi.deleteField(field.id, () => dispatch(deleteField(field.id)));
        }
    }

    function handleFieldNameChange(name) {
        farmApi.patchField(field.id, { name }, (field) => dispatch(updateField(field)));
    }

    function handleZoomToField(field, mapRef) {
        mapRef.current && mapRef.current.closePopup();
        selectedFields.current = [field.id];
        farmMapRef.current.computeBounds();
    }

    return (<Stack direction="row" alignItems="center" justifyContent="space-between">
        <SelfEditTextField
            label={t("farms.label-field-name")}
            value={field.name}
            onSave={handleFieldNameChange}
            variant="standard"
            sx={{ marginBottom: 2 }}
            fullWidth
        />
        <IconButton onClick={handleDeleteField}><DeleteForeverOutlinedIcon /></IconButton>
    </Stack>);
}