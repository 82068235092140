import { createSelector } from "reselect";

const selectState = (state) => state.farmiq;

export const selectCrops = createSelector(selectState, (state) => state.crops?.list);

export const selectCropsMap = createSelector(selectState, (state) => state.crops?.map);

export const selectFilterCrop = createSelector(selectState, (state) => state.filterCrop);

export const selectMode = createSelector(selectState, (state) => state.mode);
