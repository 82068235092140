import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DialogContent, DialogActions, TextField, FormControlLabel, RadioGroup, Radio, Button } from "@mui/material";
import { displayInfoMessage } from "@agricircle/shared/redux";
import { useModal, useValidation, NOT_EMPTY_VALIDATOR, LENGTH_VALIDATOR } from "@agricircle/shared/hooks";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { useFarmIQApi } from "../hooks/farmiq";


export default () => {
    const { closeModal } = useModal();
    const { t } = useTranslation();
    const VALIDATION_OPTIONS = {
        subject: { validator: [NOT_EMPTY_VALIDATOR, LENGTH_VALIDATOR(8, 200, t("validation-length", { min: 8, max: 200 }))] },
        body: { validator: [NOT_EMPTY_VALIDATOR, LENGTH_VALIDATOR(8, 2000, t("validation-length", { min: 8, max: 2000 }))] }
    };
    const dispatch = useDispatch();
    const farmIQApi = useFarmIQApi();
    const farm = useSelector(selectFarm);
    const validation = useValidation(VALIDATION_OPTIONS);
    const [supportForm, setSupportForm] = useState({
        type: "other",
        body: ""
    });

    useEffect(() => validation.init(supportForm), []);

    function handleSupportTypeChange(e) {
        setSupportForm({ ...supportForm, type: e.target.value });
    }

    function handleValueChange(e) {
        const property = e.target.name;
        const value = validation.validate(property, e.target.value);
        if (value !== undefined) {
            setSupportForm({ ...supportForm, [property]: value });
        }
    }

    function handleSend() {
        const form = { ...supportForm, farm_id: farm?.id };
        farmIQApi.sendSupportForm(
            form,
            () => {
                closeModal();
                dispatch(displayInfoMessage("label-support-form-sent"));
            }
        );
    }

    return (<>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            <RadioGroup value={supportForm.type} onChange={handleSupportTypeChange}>
                <FormControlLabel value="200ha" control={<Radio size="small" />} label={t("label-support-200ha")} />
                <FormControlLabel value="howto" control={<Radio size="small" />} label={t("label-support-howto")} />
                <FormControlLabel value="billing" control={<Radio size="small" />} label={t("label-support-billing")} />
                <FormControlLabel value="bug" control={<Radio size="small" />} label={t("label-support-bug")} />
                <FormControlLabel value="other" control={<Radio size="small" />} label={t("label-support-other")} />
            </RadioGroup>
            <TextField
                name="body"
                label={t("label-support-message")}
                multiline
                rows={5}
                value={supportForm.message}
                onChange={handleValueChange}
                required
                error={!validation.isValid("body")}
                helperText={validation.error("body") || " "}
                fullWidth
            />
        </DialogContent>
        <DialogActions>
            <Button
                variant="outlined"
                onClick={closeModal}
            >
                {t("btn-cancel")}
            </Button>
            <Button
                variant="contained"
                onClick={handleSend}
                disabled={!validation.isValid()}
            >
                {t("btn-support-send")}
            </Button>
        </DialogActions>
    </>);
};
