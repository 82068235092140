import { produce } from "immer";

const initialState = { items: [] };

export const messageReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case "ADD_MESSAGE":
                if (!state.items.length || action.payload.message != state.items[state.items.length - 1].message)
                    draft.items = [...state.items, action.payload];
                break;
            case "POP_MESSAGE":
                if (state.items.length > 0)
                    draft.items = state.items.slice(1);
                break;
            case "CLEAR_MESSAGES":
                draft.items = [];
                break;
        }
    });

export const displayErrorMessage = (message, errorDetails, duration) => ({
    type: "ADD_MESSAGE",
    payload: {
        message: (
            errorDetails?.error
                ? `${message} - ${errorDetails.error}`
                : errorDetails?.errors?.length
                    ? `${message} - ${errorDetails.errors[0]}`
                    : message
        ) + (errorDetails?.correlation ? ` (${errorDetails.correlation})` : ""),
        isErrorMessage: true,
        duration: duration || 4000
    },
});

export const displayInfoMessage = (message, duration) => ({
    type: "ADD_MESSAGE",
    payload: { message, isErrorMessage: false, duration: duration || 4000 },
});

export const popMessage = () => ({ type: "POP_MESSAGE" });

export const clearMessages = () => ({ type: "CLEAR_MESSAGES" });
