export const today = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset();
    const today = new Date(now.getTime() - (offset * 60 * 1000));
    return today.toISOString().split("T")[0];
};

export const monthAndDay = (t, dateStr) => {
    const d = new Date(dateStr);
    return d.toLocaleString(t.language, { day: 'numeric', month: 'long' })
};

export const isoToLocalDateTime = (t, dateStr) => {
    if (!dateStr) return null;

    const dt = new Date(dateStr.replace(/\.\d+/, ''));
    return dt.toLocaleString(t.language);
};
