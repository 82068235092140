import { createContext, useContext, useState, useRef, useEffect } from "react";
import { useFarmIQApi } from "./hooks/farmiq";

const PollingContext = createContext();

export const PollingProvider = ({ children }) => {
    const farmIQApi = useFarmIQApi();
    const [processing, setProcessing] = useState(null);
    const pollingIntervalRef = useRef(null);

    const cancelPolling = () => {
        if (pollingIntervalRef.current)
            clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
    };

    const checkCompletion = async () => {
        await farmIQApi.getAssessment(processing, (data) => {
            if (Object.keys(data).length) {
                setProcessing(null);
            }
        });
    };

    useEffect(() => {
        cancelPolling();
        if (!processing) {
            return;
        }
        const startPolling = async () => {
            // Set up polling interval
            pollingIntervalRef.current = setInterval(async () => await checkCompletion(), 15000);
        };
        startPolling();

        return cancelPolling;
    }, [processing]);

    return (
        <PollingContext.Provider value={{ isProcessing: Boolean(processing), processing, setProcessing }}>
            {children}
        </PollingContext.Provider>
    );
};

export const usePolling = () => useContext(PollingContext);
