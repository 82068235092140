import React from "react";
import { useTranslation } from "react-i18next";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

const DEFAULT_LANGUAGES = {
    en: { nativeName: "ENGLISH", short: "EN" },
    //  fr: { nativeName: "FRANCAIS" },
    //  it: { nativeName: "ITALIANO" },
    de: { nativeName: "DEUTSCH", short: "DE" },
    pt: { nativeName: "PORTUGUÊS", short: "PT" }
};

export const LanguageSelector = ({ small, languages }) => {
    const { i18n } = useTranslation();

    const handleChangeLanguage = (e, lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("agricircle_language", lng);
    };

    if (!languages)
        languages = DEFAULT_LANGUAGES;

    return (<ToggleButtonGroup value={i18n.language || "en"} exclusive onChange={handleChangeLanguage} size="small" className="language">
        {Object.keys(languages).map((lng) => <ToggleButton
            key={lng}
            value={lng}
            size="small"
            sx={{ fontSize: small ? "65%" : "80%", padding: "3px" }}
            title={small && languages[lng].nativeName || undefined}
        >
            {small ? languages[lng].short : languages[lng].nativeName}
        </ToggleButton>)}
    </ToggleButtonGroup>);
};
